import axios from "axios";
import getHeaders from "../constants/headers";
import { CONSUMER_BASE_URL } from "./BaseURL";

export const brandAllCustomerData = (brandId) => {
  return axios({
    method: "get",
    url:
      CONSUMER_BASE_URL +
      "/api/secure/v1/brand/" +
      brandId +
      "/brand-store-customer",
    headers: getHeaders(),
  });
};