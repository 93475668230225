import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Button,
} from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { getBrandStore, getDataByInvoice } from "../services/regenerate";
import { getAllBrands } from "../services/brandApi";
import { Dropdown } from "primereact/dropdown";
import { closeOrder, closeOrderInfo } from "../services/updateOrderStatus";
import { storeOrderSwap } from "../services/Orders";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { brandAllCustomerData } from "../services/brandAllCustomerData";

const BrandAllCustomerData = () => {
  const navigate = useNavigate();
  const { state } = useLocation();   

  const [loading, setLoading] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);


  const handleSubmit = async () => {    
      
      setSubmitButtonPressed(true);
    brandAllCustomerData(state)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          if (Res.status === false) {
            errorMessage(response.data.message);
            setTimeout(() => {
             navigate(-1);
            }, 2000);
          } else {
            successMessage(response.data.message);
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>Brand All Customer Data</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <div>
                           
                           
                            <div>
                              <p>
                                Please Click on Submit Button to get Customer Data 
                              </p>
                            </div>

                           
                            <div className="col-md-12 text-center">
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="move-btn-a move-btn-mu"
                                disabled={submitButtonPressed}
                                style={{ cursor: "pointer" }}
                              >
                                Submit
                              </button>
                            </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandAllCustomerData;
