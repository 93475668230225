import React from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const GeneralConfiguration = () => {
  const navigate = useNavigate();
  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate("/")}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/brand-configuration">
                Brand Configuration
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Garment View</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/view-garment"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add Garment</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/add-garment"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>View AppServices</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/view-app-services"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add AppService</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/app-service"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>View Remarks Type</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/view-remarks-type"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add Remarks Type</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/add-remarks-type"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Notification Template</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/notification-template"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>

          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add Garment Category</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/add-garment-category"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GeneralConfiguration;
