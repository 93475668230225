import axios from "axios";
import getHeaders from "../constants/headers";
import { BRAND_BASE_URL } from "./BaseURL";

export const getRegenerateInvoice = (storeId, storeCustomerId, orderId) => {
  return axios({
    method: "get",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/" +
      storeCustomerId +
      "/order/" +
      orderId +
      "/regenerate-invoice",
    headers: getHeaders(),
  });
};

export const getRegenerateQrcode = (storeId, storeCustomerId, orderId) => {
  return axios({
    method: "get",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/" +
      storeCustomerId +
      "/order/" +
      orderId +
      "/regenerate-qrcode",
    headers: getHeaders(),
  });
};

export const getBrandStore = async (brandId) => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/store/api/secure/v1/brand/" + brandId + "/store", // for android use
    headers:  getHeaders(),
  });
};

export const getDataByInvoice = async (brandId, storeId, invoiceNo) => {
  return axios({
    method: "get",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/brand/" +
      brandId +
      "/store/" +
      storeId +
      "/invoice/" +
      invoiceNo, // for android use
    headers: getHeaders(),
  });
};

export const postQrCodeByStatus = (id, storeId, orderId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/store/" +
      storeId +
      "/store-customer/" +
      id +
      "/order/" +
      orderId +
      "/qrcode-by-status",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};


export const postBulkQrCode = (id, storeId, orderId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/store/" +
      storeId +
      "/store-customer/" +
      id +
      "/order/" +
      orderId +
      "/bulk-qrcode",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};