import React from "react";
import { Col, Container, Nav, Navbar, Row, Button, Card } from "react-bootstrap";
import "../index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
const BrandConfig = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
 
  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate("/all-brands")}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Card
          style={{
            backgroundColor: "#1752AA",
            color: "white",
            marginTop: 20,
            textAlign: "center",
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h5>Brand Name : {state.name}</h5>
              </Col>
              <Col>
                <h5>Mobile Number : {state.mobileNo}</h5>
              </Col>
            </Row>
            {/*<Row>
              <Col>
                <h5>User Name : user.firstName + " " + user.lastName</h5>
              </Col>
              <Col>
                <h5>Mobile Number : user.mobileNo</h5>
              </Col>
            </Row>*/}
          </Card.Body>
        </Card>

        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Regenerate Invoice / Tag</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/regenerate", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Update Order Status</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/update-order-status", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Order Cancel</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/order-cancel", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add Price Garment Service</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/add-price-garment-service", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add Package Garment Service</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/add-package-garment-service", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Brands Notification</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/brand-notification", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Swap Order</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/swap-order", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Brand All Customer Data</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/brand-all-customer-data", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Order Tag Update</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/order-tag-update", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Opening Balance Update</Card.Title>
                <Card.Text></Card.Text>
                <div
                  onClick={() =>
                    navigate("/opening-balance-update", {
                      state: state.id,
                    })
                  }
                  className="move-btn-a move-btn-mu center-text cursor-pointer"
                  variant="primary"
                >
                  Go
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandConfig;
