import axios from "axios";
import getHeaders from "../constants/headers";
import { BRAND_BASE_URL } from "./BaseURL";

export const closeOrderInfo = (brandId, storeId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/brand/" +
      brandId +
      "/store/" +
      storeId +
      "/close-order-info",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const closeOrder = (brandId, storeId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/brand/" +
      brandId +
      "/store/" +
      storeId +
      "/close-order",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const openingBalance = (storeId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/income-expense/store/" +
      storeId +
      "/opening-balance",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};