import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Button,
} from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { getBrandStore } from "../services/regenerate";
import { Dropdown } from "primereact/dropdown";
import {
  openingBalance,
} from "../services/updateOrderStatus";
import { errorMessage, successMessage } from "../utils/alertMessage";

const OpeningBalanceUpdate = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  // const [brandList, setBrandList] = useState([]);
  const [brandStoreList, setBrandStoreList] = useState([]);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    store: "",
    openingBalance: "",
  });

  const fetchAllBrandStore = (state) => {
    getBrandStore(state)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const getBrandStore = Res.map((item, index) => ({
            name: item.storeName,
            value: item.id,
          }));
          setBrandStoreList(getBrandStore);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrandStore(state);
  }, [state]);

  const handleSubmit = async () => {
    setSubmitButtonPressed(true);
    const data = {
      storeId: input.store.value,
      storeUserId: "",
      openingBalance: input.openingBalance,
    };
    openingBalance(input.store.value, data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          if (Res.status === false) {
            errorMessage(response.data.message);
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          } else {
            successMessage(response.data.message);
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
          setLoading(false);
          setSubmitButtonPressed(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>Opening Balance Update</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <div>
                          <div>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor="qrCodeCount"
                                  className="form-label"
                                >
                                  Store Selection
                                </label>
                              </div>
                              <Dropdown
                                value={input.store}
                                options={brandStoreList}
                                optionLabel="name"
                                onChange={(e) => {
                                  setInput({
                                    ...input,
                                    store: e.target.value,
                                  });
                                }}
                                placeholder="Select a Store"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="garmentName"
                                className="form-label"
                              >
                                Opening Balance
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                textAlign: "center",
                              }}
                            >
                              <InputText
                                type="text"
                                style={{ width: "100%", marginRight: "10px" }}
                                id="openingBalance"
                                name="openingBalance"
                                value={input.openingBalance}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if(/^\d*$/.test(value)) {
                                    setInput({
                                      ...input,
                                      openingBalance: value,
                                    });
                                  }
                                }}
                                readonly
                              />
                            </div>
                          </div>

                          <div className="col-md-12 text-center">
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                              style={{ cursor: "pointer" }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OpeningBalanceUpdate;
