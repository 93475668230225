import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Button,
} from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import {
  getBrandStore,
  getDataByInvoice,
  postBulkQrCode,
  postQrCodeByStatus,
} from "../services/regenerate";
import { getAllBrands } from "../services/brandApi";
import { Dropdown } from "primereact/dropdown";
import { closeOrder, closeOrderInfo } from "../services/updateOrderStatus";
import { storeOrderSwap } from "../services/Orders";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { Checkbox } from "primereact/checkbox";

const OrderTagUpdate = () => {
  const navigate = useNavigate();

  const { state } = useLocation();   

  const [loading, setLoading] = useState(false);
  // const [brandList, setBrandList] = useState([]);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [tagInfo, setTagInfo] = useState([]);
  const [selectedQrCodeItems, setSelectedQrCodeItems] = useState([]);
  const [selectedQrCodeItemsDetails, setSelectedQrCodeItemsDetails] = useState(
    []
  );
  const [isSubmitButtonPressed,setIsSubmitButtonPressed] = useState(false)

  const statusList = [
    {
      name: "BOOKED",
      value: "BOOKED",
    },
    { name: "IN_PROCESS", value: "IN_PROCESS" },
    { name: "READY_TO_SEND_WARE_HOUSE", value: "READY_TO_SEND_WARE_HOUSE" },
    { name: "SENT_TO_WARE_HOUSE", value: "SENT_TO_WARE_HOUSE" },
    { name: "RECEIVE_BY_WARE_HOUSE", value: "RECEIVE_BY_WARE_HOUSE" },
    { name: "REJECT_BY_WARE_HOUSE", value: "REJECT_BY_WARE_HOUSE" },
    {
      name: "SENT_TO_STORE_BY_WARE_HOUSE",
      value: "SENT_TO_STORE_BY_WARE_HOUSE",
    },
    { name: "IN_PROCESS_IN_WARE_HOUSE", value: "IN_PROCESS_IN_WARE_HOUSE" },
    { name: "PROCESSED_IN_WARE_HOUSE", value: "PROCESSED_IN_WARE_HOUSE" },
    { name: "RECEIVE_FROM_WARE_HOUSE", value: "RECEIVE_FROM_WARE_HOUSE" },
    { name: "RECEIVE_BY_STORE", value: "RECEIVE_BY_STORE" },
    { name: "PROCESSED", value: "PROCESSED" },
    { name: "READY_TO_DELIVER_BY_STORE", value: "READY_TO_DELIVER_BY_STORE" },
    { name: "DELIVERED", value: "DELIVERED" },
    { name: "UNPROCESSED", value: "UNPROCESSED" },
    { name: "UNPROCESSED_BY_WARE_HOUSE", value: "UNPROCESSED_BY_WARE_HOUSE" },
    { name: "OUT_FOR_DELIVERY", value: "OUT_FOR_DELIVERY" },
    { name: "DELAY_BY_STORE", value: "DELAY_BY_STORE" },
    { name: "DELAY_BY_WARE_HOUSE", value: "DELAY_BY_WARE_HOUSE" },
  ];

  const [brandStoreList, setBrandStoreList] = useState([]);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [customerData, setCustomerData] = useState(null);

  const [input, setInput] = useState({
    // brand: "",
    store: "",
    invoiceNoFirst: "",
  });

  const fetchAllBrands = (state) => {
    setLoading(true);
     getBrandStore(state)
       .then((response) => {
         if (!response.data.error) {
           const Res = response.data;
           const getBrandStore = Res.map((item, index) => ({
             name: item.storeName,
             value: item.id,
           }));
           setBrandStoreList(getBrandStore);
           setLoading(false);
         }
       })
       .catch((err) => {
         console.error("Error:", err);
       });
    // getAllBrands()
    //   .then((response) => {
    //     if (!response.data.error) {
    //       const Res = response.data;
    //       const getAllBrands = Res.map((item, index) => ({
    //         name: item.name,
    //         value: item.id,
    //       }));
    //       setBrandList(getAllBrands);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.error("Error:", err);
    //   });
  };

  useEffect(() => {
    fetchAllBrands(state);
  }, [state]);

  const [statusValue, setStatusValue] = useState("")

  // const handleBrandIdData = (e) => {
  //   setInput({
  //     ...input,
  //     brand: e.target.value,
  //   });
  //   getBrandStore(e.target.value.value)
  //     .then((response) => {
  //       if (!response.data.error) {
  //         const Res = response.data;
  //         const getBrandStore = Res.map((item, index) => ({
  //           name: item.storeName,
  //           value: item.id,
  //         }));
  //         setBrandStoreList(getBrandStore);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error:", err);
  //     });
  // };

  const handleSubmit = async () => {
    setSubmitButtonPressed(true);
    getDataByInvoice(state, input.store.value, input.invoiceNoFirst)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setCustomerData(Res);
          if (Res !== "") {
            const data = {
              status: ["IN_PROCESS"],
            };
            postQrCodeByStatus(
              Res.storeCustomerId,
              input.store.value,
              Res.id,
              data
            )
              .then((response) => {
                if (!response.data.error) {
                  const Res = response.data.map((item) => ({
                    ...item,
                    checked: false,
                  }));
                  setTagInfo(Res);
                }
              })
              .catch((err) => {
                console.error("Error:", err);
              });
          }
          setSubmitButtonPressed(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleConfirmAndContinue = () => {
    // Use the selectedQrCodeItems array for further processing
    if (!isSubmitButtonPressed) {
      // Set the state to indicate that the button has been pressed
      if (selectedQrCodeItems.length > 0) {
        if (tagInfo.length > 0) {
          setIsSubmitButtonPressed(true);
          const qrCodeArray = selectedQrCodeItemsDetails.map(
            (item) => item.qrcode
          );
          const bulkQrData = {
            qrcodeList: qrCodeArray,
            status: statusValue.value,
            remark: "ready now",
          };
          postBulkQrCode(
            customerData.storeCustomerId,
            customerData.storeId,
            customerData.id,
            bulkQrData
          )
            .then((response) => {
              if (!response.data.error) {
                const Res = response.data;

                if (Res.status === false) {
                  errorMessage(response.data.message);
                  setTimeout(() => {
                    navigate("/");
                  }, 2000);
                } else {
                  successMessage(response.data.message);
                  setTimeout(() => {
                    navigate("/");
                  }, 2000);
                }
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
         navigate("/");
        }
      }
    }
  };

  const selectAllCheckboxes = () => {
    const updatedData = tagInfo.map((item) => ({
      ...item,
      checked: !isCheckedAll,
    }));
    setTagInfo(updatedData);
    setIsCheckedAll(!isCheckedAll);
    // Update selected items and details arrays based on the selected items after the select all operation
    if (!isCheckedAll) {
      const newSelectedQrCodeItems = tagInfo.map((item) => item.id);
      const newSelectedQrCodeItemsDetails = tagInfo.map((item) => ({
        ...item,
      }));
      setSelectedQrCodeItems(newSelectedQrCodeItems);
      setSelectedQrCodeItemsDetails(newSelectedQrCodeItemsDetails);
    } else {
      setSelectedQrCodeItems([]);
      setSelectedQrCodeItemsDetails([]);
    }
  };

  const toggleCheckBox = (itemId) => {
    setTagInfo((prevData) =>
      prevData.map(
        (item) =>
          item.id === itemId ? { ...item, checked: !item.checked } : item // Uncheck other checkboxes
      )
    );

    setSelectedQrCodeItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );

    setSelectedQrCodeItemsDetails((prevDetails) =>
      prevDetails.some((item) => item.id === itemId)
        ? prevDetails.filter((item) => item.id !== itemId)
        : [...prevDetails, tagInfo.find((item) => item.id === itemId)]
    );
  };

  const renderTagInfoItem = () => {
    return tagInfo?.map((item, index) => {
      const garmentName =
        item.garmentAlias !== "" ? item.garmentAlias : item.garment;
      return (
        <div>
          <div className="flex-1">
            <div className="flex-row py-2 px-4">
              <div className="flex justify-content-flex-start align-items-center">
                <div>
                  <Checkbox
                    size={20}
                    checked={item.checked}
                    color={item.checked ? "#00A3FF" : "#C1C9D3"}
                    onClick={() => toggleCheckBox(item.id)}
                  />
                </div>
                <div className="ml-4">
                  <span className="text-xl font-medium">{item.qrcode}</span>
                </div>
              </div>
            </div>
            <div className="flex-1" style={{ backgroundColor: "#F6FAFF" }}>
              <div className="flex flex-row">
                <div className="flex-1 px-4">
                  <div>
                    <span
                      className="text-md font-normal"
                      style={{ color: "#75879B" }}
                    >
                      Garment :
                    </span>
                    <p className="text-lg font-medium">
                      {garmentName.replace(/_/g, " ")}
                    </p>
                  </div>
                </div>
                <div className="flex-1 px-4">
                  <div>
                    <span
                      className="text-md font-normal"
                      style={{ color: "#75879B" }}
                    >
                      Services :
                    </span>
                    <p className="text-lg font-medium">
                      {item.addOnList
                        .map((addOnItem) => addOnItem.serviceName)
                        .join(", ")}
                    </p>
                  </div>
                </div>
                <div className="flex-1 px-4">
                  <div>
                    <span
                      className="text-md font-normal"
                      style={{ color: "#75879B" }}
                    >
                      Category :
                    </span>
                    <p className="text-lg font-medium">
                      {item.category.replace(/_/g, " ")}
                    </p>
                  </div>
                </div>

                <div className="flex-1 px-4">
                  <div>
                    <span
                      className="text-md font-normal"
                      style={{ color: "#75879B" }}
                    >
                      Status :
                    </span>
                    <p className="text-lg font-medium">
                      {item.status.replace(/_/g, " ")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
 

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: "#fff" }}>Order Tag Status Update</h3>
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className="mb-4 pb-4">
        <Row>
          <Col style={{ margin: 10 }}>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <div className="row gap-3">
                         {/* <div className="flex-1">
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor="category"
                                  className="form-label"
                                >
                                  Brand Selection
                                </label>
                              </div>
                              <Dropdown
                                value={input.brand}
                                options={brandList}
                                optionLabel="name"
                                onChange={handleBrandIdData}
                                placeholder="Select a Brand"
                              />
                            </div>
                          </div>*/}
                          <div className="flex-1">
                            <div>
                              <div className="row mb-3">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="qrCodeCount"
                                    className="form-label"
                                  >
                                    Store Selection
                                  </label>
                                </div>
                                <Dropdown
                                  value={input.store}
                                  options={brandStoreList}
                                  optionLabel="name"
                                  onChange={(e) => {
                                    setInput({
                                      ...input,
                                      store: e.target.value,
                                    });
                                  }}
                                  placeholder="Select a Store"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <label htmlFor="garmentName" className="form-label">
                              Invoice No.
                            </label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                            }}
                          >
                            <InputText
                              type="text"
                              style={{ width: "100%", marginRight: "10px" }}
                              id="invoiceNoFirst"
                              name="invoiceNoFirst"
                              value={input.invoiceNoFirst}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  invoiceNoFirst: e.target.value,
                                });
                              }}
                              readonly
                            />
                          </div>
                        </div>
                        <div className="col-md-12 text-center">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="move-btn-a move-btn-mu"
                            disabled={submitButtonPressed}
                            style={{ cursor: "pointer" }}
                          >
                            Submit
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
        </Row>
        <Row>
          <Col>
            {customerData !== null && (
              <React.Fragment>
                <Row>
                  <Col>
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            <th>Mobile No.</th>
                            <th>Store Customer Id</th>
                            <th>Invoice No.</th>
                            <th>Order No.</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{customerData.customerName}</td>
                            <td>{customerData.customerMobile}</td>
                            <td>{customerData.storeCustomerId}</td>
                            <td>{customerData.invoiceNo}</td>
                            <td>{customerData.orderNo}</td>
                            <td>{customerData.status}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
        </Row>
        {tagInfo.length > 0 && (
          <div className="my-4">
            <div className="flex-1 flex" style={{ backgroundColor: "#EAEFF5" }}>
              <div className="flex flex-row py-2 px-4">
                <div className="flex justify-content-center align-items-center">
                  <Checkbox
                    size={20}
                    checked={isCheckedAll}
                    color={isCheckedAll ? "#00A3FF" : "#C1C9D3"}
                    onClick={selectAllCheckboxes}
                  />
                </div>
                <div className="ml-4">
                  <span className="text-2xl font-medium">
                    Order Garment Info
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">{renderTagInfoItem()}</div>
            {selectedQrCodeItemsDetails.length > 0 &&
              
              <div className="flex-1 my-4">
              <div className="row mb-3 mx-3">
                <div className="col-md-12">
                  <label htmlFor="status" className="form-label">
                    Select Status
                  </label>
                </div>
                <Dropdown
                  value={statusValue}
                  options={statusList}
                  optionLabel="name"
                  onChange={(e) => setStatusValue(e.target.value)}
                  placeholder="Select a Status"
                />
              </div>
            </div>}
            {statusValue !== "" && 
              <Row>
              <Col>
                <div className="col-md-12 my-4 ">
                  <button
                    className="move-btn move-btn-su"
                    style={{ cursor: "pointer", width: "100%" }}
                    onClick={() => handleConfirmAndContinue()}
                  >
                    UPDATE
                  </button>
                </div>
              </Col>
            </Row>}
          </div>
        )}
      </Container>
    </div>
  );
};

export default OrderTagUpdate;
