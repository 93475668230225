import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Button,
} from "react-bootstrap";
import "../../index.css";
import {  useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import {
  getBrandStore,
  getDataByInvoice,
} from "../../../services/regenerate";
import { Dropdown } from "primereact/dropdown";

const Regenerate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();   

  // const [brandList, setBrandList] = useState([]);
  const [brandStoreList, setBrandStoreList] = useState([]);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [customerData, setCustomerData] = useState(null);
  const [regenerateInvoiceMessage, setRegenerateInvoiceMessage] = useState("");
  const [regenerateQrcodeMessage, setRegenerateQrcodeMessage] = useState("");

  const [input, setInput] = useState({
    // brand: "",
    store: "",
    invoiceNo: "",
  });

  const fetchStore = (state) => {
    getBrandStore(state)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const getBrandStore = Res.map((item, index) => ({
            name: item.storeName,
            value: item.id,
          }));
          setBrandStoreList(getBrandStore);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchStore(state);
  }, [state]);

  const handleSubmit = async (event) => {
    setSubmitButtonPressed(true);
    event.preventDefault();
    getDataByInvoice(state, input.store.value, input.invoiceNo)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setCustomerData(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleRegenerateInvoice = () => {
    if (!customerData) return;

    getDataByInvoice(
      input.store.value,
      customerData.storeCustomerId,
      customerData.id
    )
      .then((response) => {
        if (!response.data.error) {
          // const Res = response.data;
          setTimeout(() => {
            setRegenerateInvoiceMessage("Regenerate Invoice Request Received");
          }, 3000);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleRegenerateQrcode = () => {
    if (!customerData) return;

    getDataByInvoice(
      input.store.value,
      customerData.storeCustomerId,
      customerData.id
    )
      .then((response) => {
        if (!response.data.error) {
          // const Res = response.data;
          setRegenerateQrcodeMessage("Regenerate Tags Request Received");
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>Regenerate Invoice / Tag</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <form onSubmit={handleSubmit}>
                          {/*<div className="row mb-3">
                            <div className="col-md-12">
                              <label htmlFor="category" className="form-label">
                                Brand Selection
                              </label>
                            </div>
                            <Dropdown
                              value={input.brand}
                              options={brandList}
                              optionLabel="name"
                              onChange={handleBrandIdData}
                              placeholder="Select a Brand"
                            />
                          </div>*/}

                          <div>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor="qrCodeCount"
                                  className="form-label"
                                >
                                  Store Selection
                                </label>
                              </div>
                              <Dropdown
                                value={input.store}
                                options={brandStoreList}
                                optionLabel="name"
                                onChange={(e) => {
                                  setInput({
                                    ...input,
                                    store: e.target.value,
                                  });
                                }}
                                placeholder="Select a Store"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="garmentName"
                                className="form-label"
                              >
                                Invoice No.
                              </label>
                            </div>
                            <InputText
                              type="text"
                              style={{ width: "100%" }}
                              id="invoiceNo"
                              name="invoiceNo"
                              value={input.invoiceNo}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  invoiceNo: e.target.value,
                                });
                              }}
                              readonly
                            />
                          </div>

                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                              style={{ cursor: "pointer" }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 100 }}>
            {customerData !== null && (
              <div>
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Customer Name : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.customerName}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Customer Mobile : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.customerMobile}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Total Amount : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.grandTotal}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Delivered On : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.deliveredOn}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Delivery On : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.deliveryOn}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Order No : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.orderNo}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Store Customer Id : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.storeCustomerId}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <h5>Status : </h5>
                      <p style={{ paddingLeft: "10px", textAlign: "center" }}>
                        {customerData.status}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="col-md-12 text-center">
                      <button
                        className="move-btn move-btn-su"
                        style={{ cursor: "pointer" }}
                        onClick={handleRegenerateInvoice}
                      >
                        Regenerate Invoice
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="col-md-12 text-center">
                      <button
                        className="move-btn move-btn-su"
                        style={{ cursor: "pointer" }}
                        onClick={handleRegenerateQrcode}
                      >
                        Regenerate Tag
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>
                      {regenerateInvoiceMessage && regenerateInvoiceMessage}
                    </h4>
                    <h4>
                      {regenerateQrcodeMessage && regenerateQrcodeMessage}
                    </h4>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Regenerate;
