import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import {
  garmentStatusUpdate,
  getGarmentCategory,
  uploadLogo,
} from "../services/garmentCategory";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { errorMessage, successMessage } from "../utils/alertMessage";

const GarmentView = () => {
  const navigate = useNavigate();
  // const userData = getUserData();
  // let brand = userData.brandProfileData;
  // let user = userData.userProfileData;

  const [loading, setLoading] = useState(false);
  // const [orderData, setOrderData] = useState([]);

  const [categories, setCategories] = useState({});

  const [categoryMenData, setCategoryMenData] = useState([]);
  const [categoryWomenData, setCategoryWomenData] = useState([]);
  const [categoryKidData, setCategoryKidData] = useState([]);
  const [categoryOtherData, setCategoryOtherData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("MEN");
  const [enableDisableStatus, setEnableDisableStatus] = useState({});
  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayGarmentLogoUpload, setDisplayGarmentLogoUpload] =
    useState(false);
  const [garmentPreviousLogo, setGarmentPreviousLogo] = useState(null);
  const [selectedGarmentId, setSelectedGarmentId] = useState(null);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const fixedCategories = ["MEN", "WOMEN", "KID", "OTHER"];

  const fetchGarmentCategory = () => {
    setLoading(true);
    getGarmentCategory()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          let garmentMap = response.data.garmentMap;
          // Separate fixed categories and dynamic categories
          let sortedCategories = {};
          fixedCategories.forEach((cat) => {
            if (garmentMap[cat]) sortedCategories[cat] = garmentMap[cat];
          });

          // Add remaining dynamic categories
          Object.keys(garmentMap)
            .filter((cat) => !fixedCategories.includes(cat))
            .forEach((cat) => {
              sortedCategories[cat] = garmentMap[cat];
            });

          setCategories(sortedCategories);
          setSelectedCategory(fixedCategories[0]);
          setLoading(false);
          setSubmitButtonPressed(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchGarmentCategory();
  }, []);

  // let orderData;
  // switch (selectedCategory) {
  //   case "MEN":
  //     orderData = categoryMenData;
  //     break;
  //   case "WOMEN":
  //     orderData = categoryWomenData;
  //     break;
  //   case "KID":
  //     orderData = categoryKidData;
  //     break;
  //   case "OTHER":
  //     orderData = categoryOtherData;
  //     break;
  //   default:
  //     orderData = [];
  // }
  
  const orderData = categories[selectedCategory] || [];
  
  
  // useEffect(() => {
  //   // Initialize enable/disable status for each item when the component mounts
  //   const initialStatus = {};
  //   orderData.forEach((item) => {
  //     initialStatus[item.id] = item.status;
  //   });
  //   setEnableDisableStatus(initialStatus);
  // }, [orderData]);

  const handleEnableDisable = (item) => {
    const updatedStatus = !item.status;
    const garmentId = item.garment.id;
    const categoryId = item.category.id;
    // Update status locally
    // const updatedOrderData = orderData.map((dataItem) => {
    //   if (dataItem.id === item.id) {
    //     return {
    //       ...dataItem,
    //       status: updatedStatus,
    //       // garment: {
    //       //   ...dataItem.garment,
    //       //   status: updatedStatus,
    //       // },
    //     };
    //   }
    //   return dataItem;
    // });
    // Update enable/disable status
    setEnableDisableStatus((prevStatus) => ({
      ...prevStatus,
      [item.id]: updatedStatus,
    }));
    // Update order data state
    // switch (selectedCategory) {
    //   case "MEN":
    //     setCategoryMenData(updatedOrderData);
    //     break;
    //   case "WOMEN":
    //     setCategoryWomenData(updatedOrderData);
    //     break;
    //   case "KID":
    //     setCategoryKidData(updatedOrderData);
    //     break;
    //   case "OTHER":
    //     setCategoryOtherData(updatedOrderData);
    //     break;
    //   default:
    //     break;
    // }

    // Make API call to update status in backend
    // Example:
    const data = {
      id: item.id,
      garment: {
        id: garmentId,
      },
      category: {
        id: categoryId,
      },
      status: updatedStatus,
    };
    updateGarmentStatus(data);
  };

  const updateGarmentStatus = (data) => {
    setSubmitButtonPressed(true);
    garmentStatusUpdate(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          if (response.error) {
            fetchGarmentCategory();
            setSubmitButtonPressed(false);
            errorMessage("Garment Status Change Failed");
          } else {
            fetchGarmentCategory();
            successMessage("Garment Status Successfully Change");
            setSubmitButtonPressed(false);
          }
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };
  // Function to filter orderData based on search query
  const filteredData = orderData
    .filter((item) =>
      item.garment.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      // Assuming 'name' is a property of the garment object
      const nameA = a.garment.name.toLowerCase();
      const nameB = b.garment.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const onClickGarmentLogoUpload = (item) => {
    setGarmentPreviousLogo(item.garment.logo);
    setSelectedGarmentId(item.garment.id);
    setDisplayGarmentLogoUpload(true);
  };
  const onHideGarmentLogoUpload = () => {
    setFileName("");
    setSelectedImage(null);
    setDisplayGarmentLogoUpload(false);
  };

  const renderDialogGarmentLogoUpload = () => (
    <Dialog
      header="Garment Logo Upload"
      visible={displayGarmentLogoUpload}
      onHide={() => onHideGarmentLogoUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleGarmentLogoUpload}>
          <div className="row mb-3">
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label for="file-upload" className="custom-file-upload">
                  <i className="pi pi-upload"></i> Choose Garment Logo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : garmentPreviousLogo
                      ? `data:image/png;base64,${garmentPreviousLogo}`
                      : "/Img/garments.png"
                  }
                  alt=""
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                  }}
                />
                {fileName && (
                  <p
                    style={{
                      alignSelf: "center",
                      fontSize: 14,
                      fontWeight: 600,
                      margin: 10,
                    }}
                  >
                    {fileName}
                  </p>
                )}
              </div>
            </div>
          </div>
          {selectedImage && (
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="move-btn move-btn-su"
                disabled={submitButtonPressed}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );

  const handleGarmentLogoUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const logo = fileInput.files[0];

    if (!logo) {
      console.error("No file selected");
      return;
    }

    uploadLogo(selectedGarmentId, logo)
      .then((response) => {
        // handle the response
        fetchGarmentCategory();
        successMessage("Garment Logo Upload Successfully");
        setFileName("");
        setSelectedImage(null);
        setDisplayGarmentLogoUpload(false);
      })
      .catch((error) => {
        // handle errors
        fetchGarmentCategory();
        errorMessage("Garment Logo Upload Failed");
        setFileName("");
        setSelectedImage(null);
        setDisplayGarmentLogoUpload(false);
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <div
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            paddingBottom: "10px",
          }}
        >
          <Row
            className="mt-3"
            style={{
              display: "flex",
              flexWrap: "nowrap",
              gap: "10px",
            }}
          >
            {Object.keys(categories).map((category) => (
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                  key={category}
                  style={{
                    backgroundColor:
                      selectedCategory === category ? "#1752AA" : "#2b70d7",
                    // backgroundColor:
                    //   selectedCategory === "MEN" ? "#1752AA" : "#2b70d7", // Change background color if selected
                    color: "white", // Change text color if selected
                    textAlign: "center",
                  }}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              </Col>
            ))}
            {/* <Col style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor:
                  selectedCategory === "WOMEN" ? "#1752AA" : "#2b70d7", // Change background color if selected
                color: selectedCategory === "WOMEN" ? "white" : "white", // Change text color if selected
                textAlign: "center",
              }}
              onClick={() => setSelectedCategory("WOMEN")}
            >
              Women
            </button>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor:
                  selectedCategory === "KID" ? "#1752AA" : "#2b70d7", // Change background color if selected
                color: selectedCategory === "KID" ? "white" : "white", // Change text color if selected
                textAlign: "center",
              }}
              onClick={() => setSelectedCategory("KID")}
            >
              Kid
            </button>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor:
                  selectedCategory === "OTHER" ? "#1752AA" : "#2b70d7", // Change background color if selected
                color: selectedCategory === "OTHER" ? "white" : "white", // Change text color if selected
                textAlign: "center",
              }}
              onClick={() => setSelectedCategory("OTHER")}
            >
              Other
            </button>
          </Col>*/}
          </Row>
        </div>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Garment..."
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/add-garment"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add Garment
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  Garment List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Garment Category Mapping </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Garment Name</th>
                            <th>Icon</th>
                            <th>Edit</th>
                            <th>Category Enable/Disable</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.garment.name}</td>
                              <td>
                                <img
                                  src={
                                    item.garment.logo
                                      ? `data:image/png;base64,${item.garment.logo}`
                                      : "/Img/garments.png"
                                  }
                                  alt="logo"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                  }}
                                  onClick={() =>
                                    onClickGarmentLogoUpload(
                                      item,
                                      "displayGarmentLogoUpload"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/add-garment", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <InputSwitch
                                  // checked={enableDisableStatus[item.id]}
                                  // onChange={() => handleEnableDisable(item)}
                                  checked={item.status}
                                  onChange={() => handleEnableDisable(item)}
                                  disabled={submitButtonPressed}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No Garment Tag Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div>{renderDialogGarmentLogoUpload()}</div>
    </div>
  );
};

export default GarmentView;
